import React from "react";
import lf_small_logo from "../Assets/LiveFader1.svg";

const About = () => {
  return (
    <div className="about-section-container">
      {/* <div className="about-background-image-container">
        <img src={mixer2photo} alt="" />

      </div> */}


      <div className="about-section-image-container">
        <img src={lf_small_logo} alt="" />
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">About</p>
        <h1 className="primary-heading">
          The Only One on the Market! Your Tool for Mixing Live Music
        </h1>
        <p className="primary-text">
          Discover the only real-time solution on the market, with almost zero latency, allowing you to stream and amplify live events.
          Our AI automatically ensures a perfect sound mix, no matter the instruments on stage, from drums to guitar, piano, and vocals.
          With Live Fader, you have complete control over each part of the sound, while advanced algorithms balance and optimise the tone of all elements in real time, providing a professional result without any headaches!
        </p>
        <h1 className="primary-heading">
          You don't need to be a professional to manage a dozen musicians on stage! Save time and money.
        </h1>
      </div>
    </div>
  );
};

export default About;