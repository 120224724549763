import React from "react";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
// import livefaderlogo from '../Assets/LiveFader1.svg';
import livefaderlogo from '../Assets/cute_robot2_bw.jpg';

const Home = () => {
  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        {/* <div className="home-bannerImage-container"> */}
        {/* TODO: Home banner image container */}
        {/* </div> */}
        <div className="home-text-section">
          <h1 className="big-primary-heading">
            Your Tool for Mixing Live Music
          </h1>
          <p className="primary-text">
            Live Music Mastered
          </p>

          <a href="#contact">
            <button className="secondary-button">
              Subscribe Now <FiArrowRight />{" "}
            </button>
          </a>
        </div>
        <div className="home-image-section">
          <img src={livefaderlogo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Home;