/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import CommentRoundedIcon from "@mui/icons-material/CommentRounded";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";
import { BsMusicNote, BsQuestion } from "react-icons/bs";
import livefaderlogo from "../Assets/LiveFader1.svg";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const menuOptions = [
    {
      text: "Home",
      icon: <HomeIcon />,
      redirect: "#home",
    },
    {
      text: "About",
      icon: <InfoIcon />,
      redirect: "#about",
    },
    {
      text: "Samples",
      icon: <BsMusicNote />,
      redirect: "#samples",
    },
    {
      text: "Testimonials",
      icon: <CommentRoundedIcon />,
      redirect: "#testimonials",
    },
    {
      text: "FAQ",
      icon: <BsQuestion />,
      redirect: "#faq",
    },
    {
      text: "Contact",
      icon: <PhoneRoundedIcon />,
      redirect: "#contact",
    },
  ];
  return (
    <nav>
      <div className="nav-logo-container">
        <div className="nav-logo-img">
          <img src={livefaderlogo} alt="" />
        </div>
        <div className="nav-logo-text">
          LiveFader
        </div>
      </div>
      <div className="navbar-links-container">
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#samples">Samples</a>
        <a href="#testimonials">Testimonials</a>
        <a href="#faq">FAQ</a>
        <a href="#contact">Contact</a>
        {/* <button className="primary-button">Bookings Now</button> */}
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton component="a" href={item.redirect}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};

export default Navbar;