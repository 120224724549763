import React from "react";

const Testimonial = () => {
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        <p className="primary-subheading">Testimonial</p>
        <h1 className="primary-heading">What They Are Saying</h1>
        <p className="primary-text">
          Here are the reviews from our beta users:
        </p>
      </div>
      <div className="testimonial-section-bottom">
        <p>
        I recommend this product if you’re trying to smooth out a simple recording for semi-professional projects. It was super affordable, and I was really surprised about the quality. Going to a professional mix engineer for a small recording project can be really expensive, so having this option to do the exact same thing, but more affordable is worth it. Also, the rendered files came back instantly. It helped speed up the return time, and that was really important.
        </p>
        <h2>Mary, songwriter</h2>
      </div>
      {/* <div className="testimonial-section-bottom">
        <p>
        Super super super
        </p>
        <h2>Andrzej, sound engineering volounteer</h2>
      </div> */}
    </div>
  );
};

export default Testimonial;