import React from "react";
import mt_audio from '../Assets/mt_mix_1_Organic_4824.mp3';
import shake_audio from "../Assets/shake_mix_2_Organic_4824.mp3";
import iwbs_audio from "../Assets/iwbs_mix_2_Organic_4824.mp3";
import SuperGod_audio from "../Assets/supergod_mix_2_Organic_4824.mp3";
import ReactAudioPlayer from 'react-audio-player';

const Samples = () => {
    return (
        <div className="samples-section-container">

            <div className="samples-section-text-container">
                <p className="primary-subheading">Sample mixes</p>
                <h1 className="primary-heading">
                    Examples of music mixed with Live Fader
                </h1>
                <p className="primary-text">
                    Sound of Glory - His Love (mastered)
                </p>
                <ReactAudioPlayer
                    src={mt_audio}
                    // autoPlay
                    controls
                />
                <p className="primary-text">
                Sound of Glory - Shake Shake Shake (mastered)
                </p>
                <ReactAudioPlayer
                    src={shake_audio}
                    // autoPlay
                    controls
                />
                <p className="primary-text">
                Sound of Glory - I won't be shaken (mastered)
                </p>
                <ReactAudioPlayer
                    src={iwbs_audio}
                    // autoPlay
                    controls
                />
                <p className="primary-text">
                Sound of Glory - SuperGod (mastered)
                </p>
                <ReactAudioPlayer
                    src={SuperGod_audio}
                    // autoPlay
                    controls
                />
            </div>
        </div>
    );
};

export default Samples;