import React from "react";

const FAQ = () => {
  return (
    <div className="faq-section-container">
      <div className="faq-section-text-container">
        <p className="primary-subheading">Frequently asked questions</p>
        <h1 className="primary-heading">
          1. Does your product work in real-time without latency?
        </h1>
        <p className="primary-text">
          Yes, we are the only product on the market that enables live music mixing with almost zero latency, allowing you to mix and broadcast events in real-time, without any loss of quality.
        </p>
        <h1 className="primary-heading">
          2. What instruments and sound sources can be mixed?
        </h1>
        <p className="primary-text">
          Our product supports a diverse range of instruments, from acoustic to electronic, and everything in between. With the capacity to mix up to 64 stereo sound sources at once, giving you unparalleled flexibility and control over your sound.
        </p>
        <h1 className="primary-heading">
          3. Does Live Fader work automatically?
        </h1>
        <p className="primary-text">
          Yes, Live Fader automatically adjusts and balances sound levels, adapting to the dynamics and stage needs, allowing for a great mix without the need for constant manual tweaking.
        </p>
        <h1 className="primary-heading">
          4. Is the product suitable for professional sound engineers?
        </h1>
        <p className="primary-text">
          Absolutely! Our AI helps professionals save time and ensures that the sound is always fully optimized - regardless of the number of sources or complexity of the setup.
        </p>
        <h1 className="primary-heading">
          5. Will the product work well for music streaming?
        </h1>
        <p className="primary-text">
          Yes, our technology provides a flawless sound mix in real-time, allowing for live broadcasts without the need for additional processing. It's the perfect solution for concerts, performances, and live streaming.
        </p>
        <h1 className="primary-heading">
          6. Does the system require specialized equipment?
        </h1>
        <p className="primary-text">
          No, our product is compatible with most popular sound systems and devices used in professional audio, allowing for quick implementation without the need for hardware replacement.
        </p>
        <h1 className="primary-heading">
          7. How will the system handle dynamic changes in sound on stage?
        </h1>
        <p className="primary-text">
          Our AI automatically adjusts the mix in real-time to adapt to changes, ensuring that every instrument and vocal is clearly audible and balanced, regardless of the stage dynamics and varying volume levels.
        </p>
        <h1 className="primary-heading">
          8. Does the system provide manual override options?
        </h1>
        <p className="primary-text">
          Absolutely! Live Fader's automated mixing can be customized with manual adjustments, allowing users to refine the sound to their personal taste and the specific requirements of the show.
        </p>
        <h1 className="primary-heading">
          9. What benefits does Live Fader provide compared to traditional mixing methods?
        </h1>
        <p className="primary-text">
          Our AI technology delivers professional-grade sound without the need for lengthy manual adjustments. With automatic adaptation and close to zero latency, the product saves time, is more cost-effective, and is also accessible to individuals with less experience in sound engineering.
        </p>
      </div>
    </div>
  );
};

export default FAQ;