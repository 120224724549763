import React from "react";
import workphoto1 from '../Assets/workphoto1.jpg';
import workphoto2 from '../Assets/workphoto2.jpg';
import workphoto3 from '../Assets/workphoto3.jpg';

const Work = () => {
  const workInfoData = [
    {
      image: workphoto1,
      title: "Availability and quality for all",
      text: `
Top-class sound mixing doesn't have to be reserved for the highest-flying stars. Our tool offers capabilities that previously required hiring a specialist, but are now available at the click of a button. Our AI will help you achieve a professional sound with ease, regardless of your experience, at a fraction of the cost of traditional services.
`,
    },
    {
      image: workphoto2,
      title: "Efficiency gains for professionals",
      text: `
Thanks to advanced algorithms, our AI model can automatically adjust levels, EQ, compression, and other effects, traditionally a time-consuming and laborious process. You'll cut hours off your mixing time, achieving high-quality sound with minimal effort. Now you can deliver final projects to clients faster and use the saved time to focus on developing other aspects of your career.
`,
    },
    {
      image: workphoto3,
      title: "Studio-quality sound, anywhere!",
      text: `
Imagine a tool that understands your sound and automatically fine-tunes it to perfection, allowing you to focus on creativity, not technicalities. Our AI music editing and enhancement tool is your personal mixing assistant - designed to simplify the process and save you time, whether you're a professional producer or just starting out with music.
`,
    },
  ];
  return (
    <div className="work-section-wrapper">
      <div className="work-section-top">
        {/* <p className="primary-subheading">Work</p> */}
        <h1 className="primary-heading">How It Works</h1>
        <p className="primary-text">
          Our product supports up to 64 stereo sound sources, allowing you to create the perfect mix with a wealth of detail and precision that the scene demands.
          Whether it is a concert, a performance, or broadcasting live music, a great mix is now within your reach.
        </p>
      </div>
      <div className="work-section-bottom">
        {workInfoData.map((data) => (
          <div className="work-section-info" key={data.title}>
            <div className="work-section-info-boxes-img-container">
              <img src={data.image} alt="" />
            </div>
            <h2>{data.title}</h2>
            <p>{data.text}</p>
          </div>
        ))}
      </div>
      <div className="work-buttons-container">
        <a href="#faq">
          <button className="secondary-button">Learn more</button>
        </a>
      </div>
    </div>
  );
};

export default Work;